<template>
  <div class="box">
    <div class="top">
      <div style="height: 70px"></div>
      <div class="chart">
        <v-chart :option="option" autoresize class="charts" />
      </div>
      <div class="time">{{ info.updateTime }}更新</div>
      <div class="level-box">
        <div
          class="level"
          style="background: linear-gradient(to right, #2b82fd, #539efe)"
        >
          <img
            src="@/img/report/icon.png"
            v-if="info.score > 0 && info.score <= 200"
          />
          <div class="level-title">
            <!-- <image
              src="@/img/report/icon.png"
              v-if="info.score > 0 && info.score <= 200"
            ></image> -->
            <span
              :class="info.score > 0 && info.score <= 200 ? 'level-active' : ''"
            >
              一般
            </span>
          </div>
          <div class="level-num" style="left: -5rpx">0</div>
        </div>
        <div
          class="level"
          style="background: linear-gradient(to right, #4f9afd, #7db6ff)"
        >
          <img
            src="@/img/report/icon.png"
            v-if="info.score > 200 && info.score <= 400"
          />
          <div class="level-title">
            <!-- <image
              src="@/img/report/icon.png"
              v-if="info.score > 200 && info.score <= 400"
            ></image> -->

            <span
              :class="
                info.score > 200 && info.score <= 400 ? 'level-active' : ''
              "
              >中等</span
            >
          </div>
          <div class="level-num" style="left: -10rpx">200</div>
        </div>
        <div
          class="level"
          style="background: linear-gradient(to right, #65a9fb, #a0d4fe)"
        >
          <img
            src="@/img/report/icon.png"
            v-if="info.score > 400 && info.score <= 600"
          />
          <div class="level-title">
            <!-- <image
              src="@/img/report/icon.png"
              v-if="info.score > 400 && info.score <= 600"
            ></image> -->

            <span
              :class="
                info.score > 400 && info.score <= 600 ? 'level-active' : ''
              "
              >良好</span
            >
          </div>
          <div class="level-num" style="left: -10rpx">400</div>
        </div>
        <div
          class="level"
          style="background: linear-gradient(to right, #9dcbfe, #cfebfd)"
        >
          <img
            src="@/img/report/icon.png"
            v-if="info.score > 600 && info.score <= 800"
          />
          <div class="level-title">
            <!-- <image
              src="@/img/report/icon.png"
              v-if="info.score > 600 && info.score <= 800"
            ></image> -->

            <span
              :class="
                info.score > 600 && info.score <= 800 ? 'level-active' : ''
              "
              >优秀</span
            >
          </div>
          <div class="level-num" style="left: -10rpx">600</div>
        </div>
        <div
          class="level"
          style="background: linear-gradient(to right, #bceaff, #fcfdff)"
        >
          <img
            src="@/img/report/icon.png"
            v-if="info.score > 800 && info.score <= 1000"
          />
          <div class="level-title">
            <!-- <image
              src="@/img/report/icon.png"
              v-if="info.score > 800 && info.score <= 1000"
            ></image> -->

            <span
              :class="
                info.score > 800 && info.score <= 1000 ? 'level-active' : ''
              "
              >极好</span
            >
          </div>
          <div class="level-num" style="left: -10rpx">800</div>
          <div class="level-num" style="right: -20rpx">1000</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          title="一、基础信息"
          :name="1"
          title-class="collapse-title"
        >
          <div class="subtitle">1.1 基础信息</div>
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 30%">公司名称：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.name ? info.company.name : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">法定代表人：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.corporation ? info.company.corporation : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">注册地址：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.regAddress ? info.company.regAddress : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">统一社会信用代码：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.taxAccount ? info.company.taxAccount : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">成立日期：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.openingDate ? info.company.openingDate : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">注册类型：</div>
              <div class="table-td" style="width: 70%">
                {{
                  info.company.registerType ? info.company.registerType : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">注册资本(万元）：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.regCapital ? info.company.regCapital : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">所属省份：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.province ? info.company.province : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">所属市区：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.city ? info.company.city : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">行业门类：</div>
              <div class="table-td" style="width: 70%">
                {{ info.companyData.hylb ? info.companyData.hylb : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">纳税等级：</div>
              <div class="table-td" style="width: 70%">
                {{ info.companyData.nsjb ? info.companyData.nsjb : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 30%">主管税务所(科)：</div>
              <div class="table-td" style="width: 70%">
                {{
                  info.company.taxOrganization
                    ? info.company.taxOrganization
                    : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">纳税人类别：</div>
              <div class="table-td" style="width: 70%">
                {{
                  info.company.companyType == 0
                    ? "小规模"
                    : info.company.companyType == 1
                    ? "一般纳税人"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 30%">经营范围：</div>
              <div class="table-td" style="width: 70%">
                {{ info.company.scope ? info.company.scope : "--" }}
              </div>
            </div>
          </div>
          <div class="subtitle">1.2 股权信息</div>
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 25%; justify-content: center">
                股东名称
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                认缴出资额（万元）
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                出资比例
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                股东类型
              </div>
            </div>
            <div
              class="table-tr"
              v-for="(item, index) in info.holderInfoList"
              :key="index"
            >
              <div class="table-td" style="width: 25%; justify-content: center">
                {{ item.stockName ? item.stockName : "--" }}
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                {{ item.stockCapital ? item.stockCapital : "--" }}
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                {{ item.stockProportion ? item.stockProportion : "--" }}
              </div>
              <div class="table-td" style="width: 25%; justify-content: center">
                {{
                  item.stockType == 1
                    ? "公司"
                    : item.stockType == 2
                    ? "人"
                    : "其它"
                }}
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item
          title="二、经营情况"
          :name="2"
          title-class="collapse-title"
        >
          <div class="subtitle">2.1 工商信息提示</div>
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 50%">法人年龄：</div>
              <div class="table-td" style="width: 50%">
                {{ info.companyData.frnl ? info.companyData.frnl : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">法人股份比例：</div>
              <div class="table-td" style="width: 50%">
                {{ info.companyData.frcg ? info.companyData.frcg + "%" : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">
                企业成立距今月份数（月）：
              </div>
              <div class="table-td" style="width: 50%">
                {{ info.companyData.zcnx ? info.companyData.zcnx : "--" }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">近24月是否有涉诉：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.flss24 == 0
                    ? "否"
                    : info.companyData.flss24 == 1
                    ? "存在"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">
                近24月是否有经营异常
              </div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.jyyc == 0
                    ? "没有"
                    : info.companyData.jyyc == 1
                    ? "异常"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">是否为优质供应商：</div>
              <div class="table-td" style="width: 50%">
                {{ info.company.webankNum > 0 ? "是" : "否" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">商标及专利信息数：</div>
              <div class="table-td" style="width: 50%">
                {{ info.companyData.sbs + info.companyData.zls }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">
                是否为高新技术企业：
              </div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.gxjsqy == 0
                    ? "否"
                    : info.companyData.gxjsqy == 1
                    ? "是"
                    : "--"
                }}
              </div>
            </div>
          </div>
          <div class="subtitle">2.2 开票信息提示</div>
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 50%">近12月开票企业数：</div>
              <div class="table-td" style="width: 50%">
                {{ info.companyData.kpgss12 ? info.companyData.kpgss12 : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">近3个月断开票次数：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.ljdkpsj3 ? info.companyData.ljdkpsj3 : "--"
                }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">
                近12个月断开票次数：
              </div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.ljdkpsj12 ? info.companyData.ljdkpsj12 : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">近3个月开票金额：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.kpje3 ? info.companyData.kpje3 + "元" : "--"
                }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">近12个月开票金额：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.kpje12
                    ? info.companyData.kpje12 + "元"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">近24月开票金额：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.kpje24
                    ? info.companyData.kpje24 + "元"
                    : "--"
                }}
              </div>
            </div>
          </div>
          <div class="subtitle">2.3 纳税信息提示</div>
          <div class="table">
            <div class="table-tr">
              <div class="table-td" style="width: 50%">
                近12个月断缴税次数：
              </div>
              <div class="table-td" style="width: 50%">
                {{
                  info.company.companyType == 0
                    ? info.companyData.lsbcs012
                    : info.companyData.lsbcs112
                }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">
                近12个月滞纳金次数：
              </div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.companyType == 0
                    ? info.companyData.znjcs012
                    : info.companyData.znjcs112
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">近3个月纳税额度：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.zzsds3
                    ? info.companyData.zzsds3 + "元"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-th">
              <div class="table-td" style="width: 50%">近12个月纳税额度：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.zzsds12
                    ? info.companyData.zzsds12 + "元"
                    : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 50%">近24月纳税额度：</div>
              <div class="table-td" style="width: 50%">
                {{
                  info.companyData.zzsds24
                    ? info.companyData.zzsds24 + "元"
                    : "--"
                }}
              </div>
            </div>
          </div>
          <div class="subtitle">2.4 关键指标提示</div>
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 34%">指标名称</div>
              <div class="table-td" style="width: 66%">最近一期报告</div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">资产负债率</div>
              <div class="table-td" style="width: 66%">
                {{
                  info.companyData.zcfzl ? info.companyData.zcfzl + "%" : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">毛利率</div>
              <div class="table-td" style="width: 66%">
                {{ info.companyData.mll ? info.companyData.mll + "%" : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">净利率</div>
              <div class="table-td" style="width: 66%">
                {{ info.companyData.lrl ? info.companyData.lrl + "%" : "--" }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">营业收入增长率</div>
              <div class="table-td" style="width: 66%">
                {{
                  info.companyData.yysrtb ? info.companyData.yysrtb + "%" : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">营业利润增长率</div>
              <div class="table-td" style="width: 66%">
                {{
                  info.companyData.yylrtb ? info.companyData.yylrtb + "%" : "--"
                }}
              </div>
            </div>
            <div class="table-tr">
              <div class="table-td" style="width: 34%">总资产增长率</div>
              <div class="table-td" style="width: 66%">
                {{
                  info.companyData.zzctb ? info.companyData.zzctb + "%" : "--"
                }}
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item
          title="三、风险情况"
          :name="3"
          title-class="collapse-title"
        >
          <div class="table">
            <div class="table-th">
              <div class="table-td" style="width: 20%">指标口径</div>
              <div class="table-td" style="width: 80%">风险指标</div>
            </div>
            <div
              class="table-tr"
              v-for="(item, index) in info.fxtsList"
              :key="index"
              v-show="item.key != '经营风险'"
            >
              <div class="table-td" style="width: 20%">{{ item.key }}</div>
              <div class="table-td" style="width: 80%">{{ item.value }}</div>
            </div>
            <div
              class="table-tr"
              v-for="(item, index) in info.fxtsList"
              :key="index"
              v-show="item.key == '经营风险' && item.value.length > 0"
            >
              <div class="table-td" style="width: 20%">{{ item.key }}</div>
              <div
                class="table-td"
                style="
                  width: 80%;
                  flex-wrap: wrap;
                  padding-top: 0;
                  padding-bottom: 0;
                "
              >
                <div
                  style="
                    width: 100%;
                    border-bottom: 1rpx solid #ebebeb;
                    height: 72rpx;
                    line-height: 72rpx;
                  "
                  v-for="l in item.value"
                  :key="l"
                >
                  {{ l }}
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item
          title="四、预授信额度"
          :name="4"
          title-class="collapse-title"
        >
          <div class="table">
            <div class="table-tr">
              <div class="table-td" style="width: 34%">预授信额度（元）</div>
              <div class="table-td" style="width: 66%">{{ info.quota }}</div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item
          title="五、融资建议"
          :name="5"
          title-class="collapse-title"
        >
          <div class="table" style="margin-bottom: 20rpx">
            <div class="table-th">
              <div class="table-td" style="width: 20%">指标口径</div>
              <div class="table-td" style="width: 80%">融资建议</div>
            </div>
            <div
              class="table-tr"
              v-for="(item, index) in info.rzjyList"
              :key="index"
            >
              <div class="table-td" style="width: 20%">{{ item.key }}</div>
              <div class="table-td" style="width: 80%">{{ item.value }}</div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import { RadarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { TitleComponent } from "echarts/components";
// 注意，新的接口中默认不再包含 Canvas 渲染器，需要显示引入，如果需要使用 SVG 渲染模式则使用 SVGRenderer
import { CanvasRenderer } from "echarts/renderers";

echarts.use([RadarChart, GridComponent, CanvasRenderer, TitleComponent]);
import url from "@/service/url-config";
export default {
  data() {
    return {
      loanPredictionId: "",
      companyId: "",
      info: {},
      activeNames: [],
      option: {
        title: {
          text: "0", //主标题
          x: "center",
          y: "center",
          textStyle: {
            //主标题属性
            color: "#FFFFFF",
            fontWeight: "bolder",
            fontSize: 38,
          },
          itemGap: -4, //主副标题间距
          subtext: "信用良好", //副标题 \n 用于换行
          subtextStyle: {
            //副标题的属性
            color: "#F4F9FF",
            fontSize: 12,
          },
        },
        radar: {
          indicator: [
            { name: "基本信息", max: 100 },
            { name: "成长潜力", max: 100 },
            { name: "融资能力", max: 100 },
            { name: "风险情况", max: 100 },
            { name: "经营状况", max: 100 },
          ],
          // 设置雷达图中间射线的颜色
          axisLine: {
            lineStyle: {
              color: "#DCDDDF",
              type: "dashed",
            },
          },
          splitArea: {
            show: false,
            areaStyle: {
              opacity: 0, //背景透明
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#DCDDDF", // 设置网格的颜色
            },
          },
          name: {
            textStyle: {
              //名称字体样式
              color: "#F4F9FF",
              fontSize: 13,
            },
          },
          nameGap: 0, // 指示器名称和指示器轴的距离
        },
        series: [
          {
            type: "radar",
            // symbol: 'none',//拐点的样式
            symbolSize: 5, // 拐点的大小
            data: [
              {
                value: [0, 0, 0, 0, 0],
                itemStyle: {
                  //区域边框颜色
                  normal: {
                    color: "#66ADFD",
                    lineStyle: {
                      color: "#66ADFD",
                    },
                  },
                },
                label: {
                  // normal: {
                  //   //显示值
                  //   color: "#F4F9FF",
                  //   fontSize: 10,
                  //   show: true,
                  //   formatter: function (params) {
                  //     return params.value;
                  //   },
                  // },
                },
                areaStyle: {
                  normal: {
                    color: "#66ADFD", // 区域的颜色
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    smsCode(value) {
      if (value.length != 6) {
        this.errorInfo = "密码错误";
      } else {
        this.errorInfo = "";
      }
    },
  },
  created() {
    this.loanPredictionId = this.$route.query.loanPredictionId
      ? this.$route.query.loanPredictionId
      : "";
    this.companyId = this.$route.query.companyId
      ? this.$route.query.companyId
      : "";
    this.getData();
  },
  methods: {
    back() {
      this.$router.back(-1);
    },
    getData() {
      // var aaa;
      // if (this.companyId) {
      //   aaa =
      //     url.report +
      //     `?companyId=${this.companyId}&loanPredictionId=${this.loanPredictionId}`;
      // } else {
      //   aaa = url.report;
      // }
      this.$http
        .get(
          url.report +
            `?companyId=${this.companyId}&loanPredictionId=${this.loanPredictionId}`
        )
        .then((res) => {
          if (res.code == 200) {
            this.info = res.data;
            const v = res.data;
            this.option.radar.indicator = [
              { name: v.jbxx + "\n基本信息", max: 100 },
              { name: v.fzql + "\n成长潜力", max: 100 },
              { name: v.rznl + "\n融资能力", max: 100 },
              { name: v.fxqk + "\n风险情况", max: 100 },
              { name: v.jyzk + "\n经营状况", max: 100 },
            ];
            this.option.series[0].data[0].value = [
              v.jbxx,
              v.fzql,
              v.rznl,
              v.fxqk,
              v.jyzk,
            ];
            this.option.title.text = v.score;
            this.option.title.subtext = v.scoreText;
            // score
            // scoreText
          }
        });
    },
  },
};
</script>
<style>
.collapse-title {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}
</style>
<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: 100vh;
  .top {
    width: 375px;
    height: 641px;
    background: url("../../img/report/bg.png");
    background-size: 100%;
    .chart {
      width: 345px;
      height: 345px;
      background: url("../../img/report/round.png");
      background-size: 100%;
      margin: 0 auto;
    }
    .charts {
      width: 345px;
      height: 345px;
      z-index: 999;
    }

    .level-box {
      width: 315px;
      display: flex;
      margin: 0 auto;
      margin-top: 50px;
    }
    .level {
      width: 60px;
      height: 5px;
      margin-right: 2px;
      position: relative;
      img {
        width: 21px;
        height: 26px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        margin-left: -10px;
      }
    }
    .level-title {
      // width: 29px;
      width: 40px;
      height: 19rpx;
      position: absolute;
      // color: #8bc4fc;
      color: #ffffff;
      font-size: 11px;
      top: -30px;
      left: 20px;
    }
    // .level-title img {
    //   width: 21px;
    //   height: 26px;
    // }
    .level-title text {
      position: absolute;
      top: -2px;
      left: 2px;
    }
    .level-title .level-active {
      // color: #508bb0;
      background: #5785ff;
      padding: 2px 4px;
      border-radius: 8px;
    }
    .level-num {
      color: #ffffff;
      font-size: 11px;
      position: absolute;
      bottom: -20px;
    }

    .time {
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      margin-top: 26px;
    }
  }
  .bottom {
    width: 355px;
    margin: 0 auto;
    border-radius: 10px;
    background: #ffffff;
    overflow: hidden;
    position: relative;
    top: -80px;

    .subtitle {
      font-size: 13px;
      color: #333333;
      padding: 15px;
    }
    .table {
      width: 100%;
      border-top: 1px solid #ebebeb;
      border-left: 1px solid #ebebeb;
      margin: 0 auto;
      font-size: 10px;
      color: #666666;
    }
    .table-th {
      background: #fcfcfc;
      border-bottom: 1px solid #ebebeb;
      display: flex;
    }
    .table-tr {
      background: #ffffff;
      border-bottom: 1px solid #ebebeb;
      display: flex;
    }
    .table-td {
      display: flex;
      align-items: center;
      padding: 11px 5px;
      border-right: 1px solid #ebebeb;
    }
  }
}
</style>